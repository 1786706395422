// extracted by mini-css-extract-plugin
export var alignLeft = "D_rd d_fp d_bG d_dv";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignRight = "D_rf d_fr d_bH d_dx";
export var productsContainer = "D_vK d_dW";
export var productsTextWrapper = "D_vL d_0 d_9 d_w d_cG";
export var productsImageElement = "D_lF";
export var productsModalWrapper = "D_vM d_bz d_bL";
export var productsModalWrapperDesign3 = "D_l1 d_l1 d_bz d_bM d_bT d_bD d_cG d_bf";
export var modalLeftColumn = "D_vN d_r d_H";
export var modalLeftColumnDesign2 = "D_vP d_r d_H";
export var modalCloseIcon = "D_vQ d_dB";
export var modalRightColumn = "D_vR d_r d_H d_bT d_bz d_bM d_cG";
export var productsComponentsWrapperLeft = "D_lY d_lY d_y d_w d_cv d_dB";
export var modalCloseButton = "D_vS d_w d_bz d_bL d_bP d_bH";
export var modalCloseButtonDesign3 = "D_vT d_w d_bz d_bL d_bP d_bH";
export var modalTextWrapperDesign3 = "D_vV d_H d_bz d_bM d_bP";
export var modalTextWrapper = "D_vW d_bz d_bM d_bP";
export var modalCarouselWrapper = "D_vX d_H d_bz d_bL d_bD";
export var carouselWrapper = "D_k6 d_H d_bz d_bL d_bD";
export var productsCarouselImageWrapper = "D_vY d_lS d_H d_w";
export var productsCarouselImageWrapperSides = "D_vZ d_H";
export var productsCarouselImageWrapperDesign3 = "D_lT d_lT d_bf";
export var productsCarouselWrapper = "D_lV d_lV d_bz d_bD d_bP d_w d_H";
export var productsCarouselWrapperDesign3 = "D_lW d_lW d_bz d_bD d_bP d_w";
export var productsCarouselImg = "D_mX d_lX d_w d_H d_bR";
export var productsImageModalWrapper = "D_lH";
export var productsImageElementDesign3 = "D_lJ d_lJ d_hH d_w d_H d_bR";
export var productsContainerFull = "D_v0 d_dT";
export var productsMainHeader = "D_l3 d_l3 d_l2 d_w d_ct";
export var productsMainSubtitle = "D_l4 d_l4 d_l2 d_w d_cy";
export var productsComponentHeading = "D_l5 d_l5";
export var productsComponentParagraph = "D_l6 d_l6";
export var productsCard = "D_lK d_lK d_bd d_0 d_8 d_7 d_4 d_9 d_by d_y d_W d_H";
export var productsCardWrapper = "D_lL d_lL d_by d_w d_Z";
export var productsCardWrapperDesign2 = "D_lM d_lM d_w d_H d_bz d_bM d_bT";
export var productsTextBlockDesign2Wrapper = "D_v1 d_cG";
export var productsTextBlockDesign3Wrapper = "D_lN d_lN d_w d_bz d_bM d_bD d_bP d_cG d_fn d_0";
export var productsTextBlockDesign2 = "D_lP d_lP d_bz d_bM d_H";
export var productsTextBlockDesign3 = "D_lQ d_lQ d_bz d_bM d_bP d_bl";
export var productsComponentsWrapperDesign2 = "D_lZ d_lZ d_y d_w d_bz d_bM d_cv d_bT";
export var productsComponentsWrapperDesign3 = "D_l0 d_l0 d_y d_w d_bz d_bM d_cv d_dB";
export var colEqualHeights = "D_v2 d_bz";
export var productsImageWrapperLeft = "D_lB d_lB d_w d_Z d_bx";
export var productsImageWrapperDesign2 = "D_lC d_lC d_w";
export var design2TextWrapper = "D_v3 d_r d_bC d_bP d_bJ";
export var exceptionWeight = "D_vq y_tf";