// extracted by mini-css-extract-plugin
export var alignLeft = "C_rd d_fp d_bG d_dv";
export var alignCenter = "C_bP d_fq d_bD d_dw";
export var alignRight = "C_rf d_fr d_bH d_dx";
export var menuContainer = "C_vH d_dW";
export var menuContainerFull = "C_vJ d_dT";
export var menuMainHeader = "C_jC d_jC d_w d_c3";
export var menuComponentWrapper = "C_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "C_jH d_jH d_cy";
export var menuComponentText = "C_jD d_jD d_cn";
export var menuComponentTextDesign2 = "C_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "C_jJ d_jJ d_w d_Z";