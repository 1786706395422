// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "J_wl d_bD";
export var storyRowWrapper = "J_hx d_hx d_bK";
export var storyLeftWrapper = "J_wm d_bz d_bP";
export var storyWrapperFull = "J_wn d_cD";
export var storyWrapperFullLeft = "J_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "J_mv d_hy";
export var storyLeftWrapperCenter = "J_wp d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "J_wq d_hF";
export var storyHeader = "J_wr d_hD d_w d_cs";
export var storyHeaderCenter = "J_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "J_hB d_hB d_by d_dw";
export var storyBtnWrapper = "J_ws d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "J_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "J_rj d_fg d_Z";
export var imageWrapperFull = "J_wt d_w d_H d_bf d_Z";
export var SubtitleSmall = "J_pd y_pd y_sP y_s0";
export var SubtitleNormal = "J_pf y_pf y_sP y_s1";
export var SubtitleLarge = "J_pg y_pg y_sP y_s2";
export var textLeft = "J_dv";
export var textCenter = "J_dw";
export var textRight = "J_dx";