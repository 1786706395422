// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "F_v4 d_gS d_cw d_dv";
export var quoteParagraphCenter = "F_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "F_gV d_gV d_cw d_dx";
export var quoteRowLeft = "F_v5 d_bG";
export var quoteRowCenter = "F_v6 d_bD";
export var quoteRowRight = "F_v7 d_bH";
export var quoteWrapper = "F_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "F_gR d_gR";
export var quoteExceptionSmall = "F_tH y_tH";
export var quoteExceptionNormal = "F_tJ y_tJ";
export var quoteExceptionLarge = "F_tK y_tK";
export var quoteAuthorExceptionSmall = "F_tL y_tL";
export var quoteAuthorExceptionNormal = "F_tM y_tM";
export var quoteAuthorExceptionLarge = "F_tN y_tN";