// extracted by mini-css-extract-plugin
export var carouselContainer = "R_xg d_w d_H d_bf d_Z";
export var carouselContainerCards = "R_xh R_xg d_w d_H d_bf d_Z";
export var carouselContainerSides = "R_xj d_w d_H d_Z";
export var prevCarouselItem = "R_xk d_w d_H d_0 d_k";
export var prevCarouselItemL = "R_xl R_xk d_w d_H d_0 d_k";
export var moveInFromLeft = "R_xm";
export var prevCarouselItemR = "R_xn R_xk d_w d_H d_0 d_k";
export var moveInFromRight = "R_xp";
export var selectedCarouselItem = "R_xq d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "R_xr R_xq d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "R_xs R_xq d_w d_H d_Z d_bf";
export var nextCarouselItem = "R_xt d_w d_H d_0 d_k";
export var nextCarouselItemL = "R_xv R_xt d_w d_H d_0 d_k";
export var nextCarouselItemR = "R_xw R_xt d_w d_H d_0 d_k";
export var arrowContainer = "R_xx d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "R_xy R_xx d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "R_xz R_xy R_xx d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "R_xB R_xx d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "R_xC";
export var nextArrowContainerHidden = "R_xD R_xB R_xx d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "R_kG d_0";
export var prevArrow = "R_xF R_kG d_0";
export var nextArrow = "R_xG R_kG d_0";
export var carouselIndicatorContainer = "R_xH d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "R_xJ d_w d_bz d_bF";
export var carouselText = "R_xK d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "R_xL R_xK d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "R_xM d_b7";
export var carouselIndicator = "R_xN R_xM d_b7";
export var carouselIndicatorSelected = "R_xP R_xM d_b7";
export var arrowsContainerTopRight = "R_xQ d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "R_xR d_0 d_bl d_bC";
export var arrowsContainerSides = "R_xS d_0 d_bl d_bC";
export var smallArrowsBase = "R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "R_xV R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "R_xW R_xV R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "R_xX R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "R_xY R_xX R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "R_n9";
export var iconSmall = "R_xZ";
export var multipleWrapper = "R_x0 d_bC d_bF d_bf";
export var multipleImage = "R_x1 d_bC";
export var sidesPrevContainer = "R_x2 R_xV R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "R_x3 R_xV R_xT d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";