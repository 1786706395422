// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "N_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "N_fR d_fR d_bz d_bJ";
export var menuDesign6 = "N_wG d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "N_wH d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "N_wJ d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "N_wK d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "N_wL d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "N_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "N_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "N_wM";
export var navbarItem = "N_nb d_bx";
export var navbarLogoItemWrapper = "N_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "N_wN d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "N_wP d_gd d_by d_Z d_bs";
export var burgerToggle = "N_wQ d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "N_wR d_gd d_by d_Z d_bs";
export var burgerInput = "N_wS d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "N_wT d_f3 d_w d_H";
export var burgerLine = "N_wV d_f1";
export var burgerMenuDesign6 = "N_wW d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "N_wX d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "N_wY d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "N_wZ d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "N_w0 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "N_w1 d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "N_w2 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "N_w3 d_bC d_bP";
export var compact = "N_w4";
export var navDivided = "N_w5";
export var staticBurger = "N_w6";
export var menu = "N_w7";
export var navbarDividedLogo = "N_w8";
export var nav = "N_w9";
export var fixed = "N_xb";