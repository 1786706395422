// extracted by mini-css-extract-plugin
export var alignDiscLeft = "z_t0 d_fp d_bG d_dv";
export var alignLeft = "z_rd d_fp d_bG d_dv";
export var alignDiscCenter = "z_t1 d_fq d_bD d_dw";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignDiscRight = "z_t2 d_fr d_bH d_dx";
export var alignRight = "z_rf d_fr d_bH d_dx";
export var footerContainer = "z_t3 d_dW d_bW";
export var footerContainerFull = "z_t4 d_dT d_bW";
export var footerHeader = "z_kf d_kf";
export var footerTextWrapper = "z_t5 d_w";
export var footerDisclaimerWrapper = "z_km d_km d_ck";
export var badgeWrapper = "z_t6 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "z_t7 d_bz d_bJ d_bN d_bL";
export var wide = "z_t8 d_cy";
export var right = "z_t9 d_bK";
export var line = "z_fk d_fl d_cv";
export var badgeDisclaimer = "z_vb d_bC d_bP d_bJ";
export var badgeContainer = "z_vc d_bz d_bH d_bP";
export var badge = "z_vd";
export var padding = "z_vf d_c7";
export var end = "z_vg d_bH";
export var linkWrapper = "z_vh d_dB";
export var link = "z_mC d_dB";
export var colWrapper = "z_vj d_cx";
export var consent = "z_f d_f d_bC d_bP";
export var disclaimer = "z_vk d_bz d_bJ";
export var media = "z_vl d_bx d_dy";
export var itemRight = "z_vm";
export var itemLeft = "z_vn";
export var itemCenter = "z_vp";
export var exceptionWeight = "z_vq y_tf";